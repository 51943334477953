<template>
  <div class="wrap" id="words">
    <div class="fanhui"><a href="javascript:history.go(-1)">返回</a></div>
    <h1 class="mytitle">与 {{ elite_User.weixin_nickname }} 的聊天记录</h1>
    <div class="chat-div" id="chat-div">
      <div class="chat-content" id="chat-content">
        <!-- recordContent 聊天记录数组-->
        <div v-for="(itemc, indexc) in chatItems" :key="indexc">
          <!-- 对方 -->
          <div class="word" v-if="itemc.speaker == 'server'">
            <img :src="elite_User.weixin_pic" />
            <div class="info">
              <p class="time">
                {{ elite_User.weixin_nickname }} {{ itemc.time }}
              </p>
              <div class="info-content">{{ itemc.content }}</div>
            </div>
          </div>
          <!-- 我的 -->
          <div class="word-my" v-else>
            <div class="info">
              <p class="time">
                {{ company_data.company_name }} {{ itemc.time }}
              </p>
              <div class="info-content">{{ itemc.content }}</div>
            </div>
            <img :src="company_data.company_logo" />
          </div>
        </div>
      </div>
    </div>

    <div class="send_div">
      <el-input style="" v-model="add_str" class="input-with-select">
        <el-button @click="chatadd" slot="append" style="color: #ff0000"
          >发送</el-button
        >
      </el-input>
    </div>
  </div>
</template>

<script>
import { chat_detail_gl, chat_add_gl } from "@/api/chat/index.js";

export default {
  name: "",
  data() {
    return {
      chatItems: [],
      elite_User: [],
      company_data: [],
      jd_item: [],
      msg_max_id: 0,
      add_str: "",
      elite_id: "",
      jd_id: "",
      user_detail_id: "",
      timer: 0,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    // better-scroll的代码

    init() {
      var elite_id = this.$route.query.elite_id;
      if (elite_id) {
        this.get_detail();

        this.timer = setInterval(this.get_detail, 5000);
      }
    },

    get_detail() {
      var elite_id = this.$route.query.elite_id;
      var jd_id = this.$route.query.jd_id;
      var user_detail_id = this.$route.query.user_detail_id;

      this.elite_id = elite_id;

      this.jd_id = jd_id;

      this.user_detail_id = user_detail_id;

      chat_detail_gl({
        elite_id: elite_id,
        jd_id: jd_id,
        user_detail_id: user_detail_id,
      }).then((res) => {
        if (!res.code) {
          this.elite_User = res.data.elite_User;
          if (res.data.msg_max_id > this.msg_max_id) {
            this.jd_item = res.data.jd_item;

            this.chatItems = res.data.chat_list;

            this.elite_User = res.data.elite_User;
            this.company_data = res.data.company_data;

            this.msg_max_id = res.data.msg_max_id;

            //页面渲染完成后执行 开始 代码
            this.$nextTick(function () {
              this.myscroll();
            });
          }
          //页面渲染完成后执行 结束 代码
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //发送消息
    chatadd() {
      if (this.add_str != "") {
        chat_add_gl({
          elite_id: this.elite_id,
          jd_id: this.jd_id,
          content: this.add_str,
          user_detail_id: this.user_detail_id,
        }).then((res) => {
          this.add_str = "";

          //把最新消息添加进去
          this.get_detail();
          this.$message.success("发送成功");
          // this.chatItems.push({
          // 	'content': [
          // 		{
          // 			'span': 24,
          // 			'url': 'www.1.com',
          // 		},
          // 	]
          // },)

          // this.myscroll();
        });
      }
    },

    myscroll() {
      // window.location.href = "#send_hr";

      var div = document.getElementById("chat-div");
      var div_c = document.getElementById("chat-content");

      console.log(div_c.scrollHeight, div.scrollHeight);
      div.scrollTop = div_c.scrollHeight;
    },
  },

  created() {
    console.log("created");
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.mytitle {
  text-align: center;
}
.chat-div {
  border: 10px solid;
  margin: 0 auto;
  width: 600px;

  height: 600px;
  overflow: auto;
}
.send_div {
  bottom: 0px;
  width: 620px;

  margin: 0 auto;

  margin-top: 10px;
}
.chat-content {
  width: 93%;
  padding: 20px;
  .word {
    display: flex;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      margin-left: 10px;
      .time {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.8);
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-top: -5px;
      }
      .info-content {
        padding: 10px;
        font-size: 14px;
        background: #fff;
        position: relative;
        margin-top: 8px;
        word-break: break-all;
      }
      //小三角形
      .info-content::before {
        position: absolute;
        left: -8px;
        top: 8px;
        content: "";
        border-right: 10px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }
  }

  .word-my {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      width: 90%;
      margin-left: 10px;
      text-align: right;
      .time {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.8);
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-top: -5px;
        margin-right: 10px;
      }
      .info-content {
        max-width: 70%;
        padding: 10px;
        font-size: 14px;
        float: right;
        margin-right: 10px;
        position: relative;
        margin-top: 8px;
        background: #a3c3f6;
        text-align: left;
        word-break: break-all;
      }
      //小三角形
      .info-content::after {
        position: absolute;
        right: -8px;
        top: 8px;
        content: "";
        border-left: 10px solid #a3c3f6;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }
  }
}

.fanhui {
  font-size: 20px;
  margin-left: 200px;
  margin-top: 80px;
}
</style>